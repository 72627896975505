import React, { PureComponent } from 'react';
import get from 'lodash/get';
import { ToolSetOptions, ToolSetOptionsItem } from '../../ToolSet';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import {engineTypes} from '../../../utils/engineTypeHelper';
import Link from '../../SEO/Link';

class FilterEngineType extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      engineTypes: props.engineTypes || engineTypes,
      engine: undefined
    };
  }

  updateMultiInput() {
    const engineTypes = this.props.engineTypes || this.state.engineTypes;
    if (get(this.props, 'engine')) {
      this.setState({ engine: this.props.engine, engineTypes });
    } else {
      this.setState({ engine: 'all', engineTypes });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  onClickToolSetOptionsItem = (value) => {
    if (this.props.tracking) {
      this.props.tracking.facetAdded({ 'engine': value });
    }
    this.props.handleDataChange('engine', value);
  }

  onClickEngineTypeLink = (e) => {
    e.preventDefault();
  }

  render() {
    let { engineTypes, engine } = this.state;
    let { position, params, searchPage } = this.props;
    return <div className="search-filter">
      <div className="engine-types">
        <ToolSetOptions>
          { engineTypes.map(engineType => {
            return <ToolSetOptionsItem
              key={`Engine-${engineType.id}`}
              id={`Engine-${engineType.id}`}
              name={`Engine-${position}`}
              value={engineType.id}
              selected={engine === engineType.id}
              onClick={this.onClickToolSetOptionsItem}
              optionClass={engineType.optionClass || ''}
            >
              <Link className="engine-type-link"
                href={generateSearchPath({engine: engineType.id}, params, true, searchPage)}
                onClick={this.onClickEngineTypeLink}
              >{engineType.name}</Link>
            </ToolSetOptionsItem>;
          })
          }
        </ToolSetOptions>
      </div>
    </div>;
  }
}

export default FilterEngineType;
